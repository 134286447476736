import { TFunction } from 'i18next';
import { IMultiLanguageString } from '../IMultiLanguageString';
import { IDepartmentRef } from './IDepartment';
import { IBaseRef } from './IBaseRef';
import { v4 as uuidv4 } from 'uuid';
export const NewTopicId = 'new';
export interface ITopic extends IBaseRef {
  id: string;
  name: IMultiLanguageString;
  showSeparately: boolean;
  criteria: ICriterion[];
}

export interface ITopicWithDepartmentUsages extends ITopic {
  usedInDepartments: IDepartmentRef[];
  usedInDepartmentsUI?: string;
}

export interface ICriterion {
  key: string;
  description: IMultiLanguageString;
  risk: RiskTypes;
}

export interface ITopicResponse {
  topics: ITopicWithDepartmentUsages[];
}

export function createTopic(options: Partial<ITopic> = {}): ITopic {
  return Object.assign({ id: '', name: '', showSeparately: false, criteria: [] }, options);
}

export function createCriterion(options: Partial<ICriterion> = {}): ICriterion {
  return Object.assign({ key: uuidv4(), description: '', risk: '' }, options);
}

export enum RiskTypes {
  NotSet = 'NotSet',
  None = 'None',
  Medium = 'Medium',
  High = 'High',
}

export function getRiskTypeOptions(t: TFunction): IRiskOption[] {
  return ['None', 'Medium', 'High'].map((v) => ({
    value: v,
    desc: t('masterdata.topics.types.' + v),
  }));
}

export function riskToString(t: TFunction, risk: RiskTypes): string {
  risk = risk || RiskTypes.NotSet;

  return t('riskTypes.' + risk);
}

export interface IRiskOption {
  value: string;
  desc: string;
}
