import { message } from 'antd';
import i18next from 'i18next';

export const notifySaveSuccess = (trKey?: string) => {
  trKey = trKey || 'common.notifications.saveSuccessful';
  message.success(i18next.t(trKey), 2);
};

export const notifyError = (trKey: string) => {
  message.error(i18next.t(trKey), 5);
};

export const notifySaveFailed = () => {
  message.error(i18next.t('common.notifications.saveFailed'), 5);
};
