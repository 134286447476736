import { IMultiLanguageString } from '../IMultiLanguageString';
import { ILanguage } from '../LanguageSettings';

export const NewBranchOfIndustryId = 'new';
export interface IBranchOfIndustry extends IBranchOfIndustryRef {
  id: string;
  name: IMultiLanguageString;
  defaultLanguage: ILanguage;
}

export interface IBranchOfIndustryRef {
  id: string;
  name: IMultiLanguageString;
}

export interface IBranchOfIndustryCommand extends IBranchOfIndustry {
  branchIdToCopy: IBranchOfIndustryRef;
}

export function createBranchOfIndustry(options: Partial<IBranchOfIndustry> = {}): IBranchOfIndustry {
  return Object.assign({ id: '', name: '', defaultLanguage: null }, options);
}

export function createBranchOfIndustryCommand(branchOfIndustry: IBranchOfIndustry, branchIdToCopy?: IBranchOfIndustryRef): IBranchOfIndustryCommand {
  return { id: branchOfIndustry.id, name: branchOfIndustry.name, defaultLanguage: branchOfIndustry.defaultLanguage, branchIdToCopy: branchIdToCopy };
}
