import { IMultiLanguageString } from '../IMultiLanguageString';

export const NewCountryId = 'new';
export interface ICountryRef {
  id: string;
  name: IMultiLanguageString;
}
export interface ICountry extends ICountryRef {
  id: string;
  name: IMultiLanguageString;
  isoCode3Letters: string;
  isoCode2Letters: string;
  icon: string;
}

export function createCountry(options: Partial<ICountry> = {}): ICountry {
  return Object.assign({ id: '', name: '', isoCode3Letters: '', isoCode2Letters: '', icon: '' }, options);
}
