import { TFunction } from 'i18next';
import { ITopic } from './ITopic';
import { IMultiLanguageString } from '../IMultiLanguageString';
import { IBaseRef } from './IBaseRef';
import { ICheckableTag } from '../../shared/components/CheckableTagGroup/CheckableTagGroup';
import { IDepartmentRefWithTenantAndBranch } from '../user';

export const NewDepartmentId = 'new';
export interface IDepartment {
  id: string;
  name: IMultiLanguageString;
  type: DepartmentTypes;
  abbreviation?: IMultiLanguageString;
  assignedTopics: ITopic[];
  isManagement: boolean;
  assessmentVisibility: AssessmentVisibilityTypes;
  copyClaimsFrom: IDepartmentRefWithTenantAndBranch;
}

export interface IDepartmentRef extends IBaseRef {}

export interface IDepartmentResponse {
  departments: IDepartment[];
}

export interface IDepartmentResponseSingle {
  department: IDepartment;
}

export enum DepartmentTypes {
  Sales = 'Sales',
  SpecialistDepartment = 'SpecialistDepartment',
  Approval = 'Approval',
}

export function getDepartmentTypeOptions(t: TFunction): IDepartmentOption[] {
  return ['Sales', 'SpecialistDepartment', 'Approval'].map((v) => ({
    tag: v,
    title: t('masterdata.departments.types.' + v),
    value: v,
    desc: t('masterdata.departments.types.' + v),
  }));
}

export interface IDepartmentOption extends ICheckableTag {
  value: string;
  desc: string;
}

export enum AssessmentVisibilityTypes {
  All = 'All',
  OnlyOwn = 'OnlyOwn',
}

export function getAssessmentVisibilityTypeOptions(t: TFunction): IAssessmentVisibilityTypeOptions[] {
  return [AssessmentVisibilityTypes.All, AssessmentVisibilityTypes.OnlyOwn].map((v) => ({
    value: v,
    desc: t('masterdata.departments.assessmentVisibilityTypes.' + v),
  }));
}

interface IAssessmentVisibilityTypeOptions {
  value: string;
  desc: string;
}

export function createDepartment(options: Partial<IDepartment> = {}): IDepartment {
  return Object.assign(
    { id: '', name: '', type: DepartmentTypes.Sales, assignedTopics: [], isManagement: false, assessmentVisibility: AssessmentVisibilityTypes.All, copyClaimsFrom: {} as IDepartment },
    options,
  );
}
