import axios, { AxiosInstance } from 'axios';
import { IAppConfig, IClientSettings, IIdentityServerConfig } from '../../models/IAppConfig';
import { ILanguage } from '../../models/LanguageSettings';
import { IMultiLanguageString } from '../../models/IMultiLanguageString';

class SettingsService {
  private http: AxiosInstance;
  private config: IAppConfig = {} as IAppConfig;

  constructor() {
    this.http = this.initHttp();
  }
  public async init() {
    const result = await this.http.get<IAppConfig>('/config.json');
    const config = result.data;

    if (!config.apiInstanceUrl) {
      config.apiInstanceUrl = window.location.origin;
    }
    if (!config.identity) {
      config.identity = {} as IIdentityServerConfig;
    }
    if (!config.identity.authority) {
      config.identity.authority = window.location.origin;
    }
    if (!config.identity.redirecBaseUrl) {
      config.identity.redirecBaseUrl = window.location.origin;
    }

    const apiResult = await this.http.get<IClientSettings>(`${config.apiInstanceUrl}/api/clientsettings`);
    if (!config.doxTemplateLocation) {
      config.doxTemplateLocation = apiResult.data.doxTemplateLocation;
    }
    if (!config.doxWebServiceLocation) {
      config.doxWebServiceLocation = apiResult.data.doxWebServiceLocation;
    }

    if (!config.availableLanguages) {
      config.availableLanguages = apiResult.data.availableLanguages;
    }

    this.config = config;
  }

  public getConfig(): IAppConfig {
    return this.config || ({} as IAppConfig);
  }

  private initHttp(): AxiosInstance {
    const http = axios.create({
      headers: {
        Accept: 'application/json;odata=verbose',
        'Content-Type': 'application/json;odata=verbose',
      },
    });
    return http;
  }
}

const instance = new SettingsService();
export default instance;
