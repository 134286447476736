import React from 'react';
import { UserInfo } from './models/user/UserInfo';
import { LanguageSettings, ILanguage } from './models/LanguageSettings';
import { IMultiLanguageString } from './models/IMultiLanguageString';

export const UserInfoCtx = React.createContext<IUserInfoCtx>({
  userInfo: null,
  languageSettings: null,
  t: null,
  getActiveLanguageKey: () => '',
});

export interface IUserInfoCtx {
  userInfo: UserInfo;
  languageSettings: LanguageSettings;
  t: (text: IMultiLanguageString, lang?: ILanguage) => string;
  getActiveLanguageKey(): string;
}
