import { IMultiLanguageString } from '../IMultiLanguageString';

export interface IGeneralSettingsEditVm {
  tenant: ITenantConfig;
  branchesOfIndustry: IBranchOfIndustryConfig[];
}

export interface ITenantConfig {
  id: string;
  name: string;
  settings: ITenantSettings;
  terms: ITenantTerms;
}

export interface ITenantSettings {
  hasVendorLegalEntity: boolean;
  hasMachineType: boolean;
  hasConstructionSiteCountry: boolean;
  hasProjectDiagnosis: boolean;
  hasNewCustomerFlag: boolean;
  hasEstimatedDateOfTurnover: boolean;
  hasIndicationOfMainScope: boolean;
  tenantSettings: boolean;
  allowOnlyApprovalDepartmentsForApprovalStage: boolean;
  currency: string;
  defaultRiskInPercent?: number;
  useDefaultRiskValue: boolean;
  allowedReturnStages: AllowedReturnStages;
}

export enum AllowedReturnStages {
  All = 0,
  BLV = 1,
  GFBMS = 2,
  GFBGG = 4,
}

export interface ITenantTerms {
  marginalReturn: IMultiLanguageString;
  salesDepartment: IMultiLanguageString;
  commissionNumber: IMultiLanguageString;
  incotermsSupplement: IMultiLanguageString;
  contractAndCalculationDocuments: IMultiLanguageString;
  pmCalculationDocuments: IMultiLanguageString;
  generalDocuments: IMultiLanguageString;
  vendor: IMultiLanguageString;
  constructionSiteCountry: IMultiLanguageString;
}

export interface IBranchOfIndustryConfig {
  id: string;
  name: IMultiLanguageString;
  settings: IBranchOfIndustrySettings;
  terms: IBranchOfIndustryTerms;
}

export interface IBranchOfIndustrySettings {
  currency: string;
  defaultRiskInPercent?: number;
  useDefaultRiskValue: boolean;
}

export interface IBranchOfIndustryTerms {
  marginalReturn: IMultiLanguageString;
  salesDepartment: IMultiLanguageString;
  commissionNumber: IMultiLanguageString;
  incotermsSupplement: IMultiLanguageString;
  contractAndCalculationDocuments: IMultiLanguageString;
  pmCalculationDocuments: IMultiLanguageString;
  generalDocuments: IMultiLanguageString;
}

export type CommonConfig = ITenantConfig & IBranchOfIndustryConfig;
