import { ICountryRef } from './ICountry';
import { IMultiLanguageString } from '../IMultiLanguageString';

export const NewCustomerId = 'new';
export interface ICustomer extends ICustomerRef {
  id: string;
  debitorNumber: string;
  description: IMultiLanguageString;
  debitorName: IMultiLanguageString;
  name: IMultiLanguageString;
  country: ICountryRef;
  companyGroup: ICompanyGroupRef;
}

export interface ICustomerRef {
  id: string;
  name: IMultiLanguageString;
  country?: ICountryRef;
  companyGroup?: ICompanyGroupRef;
  debitorNumber?: string;
}

export interface ICompanyGroupRef {
  id: string;
  name: IMultiLanguageString;
  country?: ICountryRef;
}

export function createCustomer(options: Partial<ICustomer> = {}): ICustomer {
  const customer = Object.assign({ id: '', debitorNumber: '', description: '', debitorName: '', name: '', country: null, companyGroup: null }, options);
  customer.name = customer.debitorName;
  return customer;
}
