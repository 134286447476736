import { User } from 'oidc-client';
import { createStandardAction } from 'typesafe-actions';
import { UserInfo } from '../models/user/UserInfo';

// export const loginUser = (user: User) => action("loginUser", user);
export const loginUserAction = 'loginUser';

export const loginUser = createStandardAction(loginUserAction).map((userAndSettings: IUserAndSettingsParam) => ({
  payload: userAndSettings,
}));

export interface IUserAndSettingsParam {
  user: User;
  userInfo: UserInfo;
}

export function createIUserAndSettingsParam(user: User, userInfo: UserInfo) {
  return {
    user: user,
    userInfo: userInfo,
  };
}
