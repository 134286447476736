export const AzureAdObjectIdentifier = 'http://schemas.microsoft.com/identity/claims/objectidentifier';
export const Issuer = 'https://www.halvotec.de/Apps/Risk';
export const ValueTypeString = 'http://www.w3.org/2001/XMLSchema#string';

export const AppAdministrator = 'AppAdministrator';
export const TenantAdministrator = 'TenantAdministrator';
export const TenantId = 'TenantId';
export const BranchId = 'BranchId';
export const DepartmentId = 'DepartmentId';
export const AccessFromDepartmentSync = 'AccessFromDepartmentSync';

// RiskAssessment
export const CanCreateRiskAssessment = 'CanCreateRiskAssessment';

// Metadata
export const CanReadMasterdata = 'CanReadMasterdata';
export const CanEditCountries = 'CanEditCountries';
export const CanEditCustomers = 'CanEditCustomers';
export const CanEditMachineType = 'CanEditMachineType';
export const CanEditGeneralSetting = 'CanEditGeneralSetting';
export const CanEditDepartments = 'CanEditDepartments';
export const CanEditTenantSettings = 'CanEditTenantSettings';
export const CanEditTopics = 'CanEditTopics';
export const CanEditIncoterms = 'CanEditIncoterms';
export const CanEditBranchesOfIndustry = 'CanEditBranchesOfIndustry';
export const CanDeleteBranchesOfIndustry = 'CanDeleteBranchesOfIndustry';
export const CanEditRiskAssessmentTemplates = 'CanEditRiskAssessmentTemplates';

// Branch Claims
export const CanEditAppendicesDocuments = 'CanEditAppendicesDocuments';
export const CanEditSafeDocuments = 'CanEditSafeDocuments';
export const CanEditUsers = 'CanEditUsers';
export const CanEditConfig = 'CanEditConfig';
export const IsProjectAdmin = 'IsProjectAdmin';
export const CanPrintRiskAnalysisReport = 'CanPrintRiskAnalysisReport';
export const CanEditContractDocuments = 'CanEditContractDocuments';
export const CanEditPmCalcDocuments = 'CanEditPmCalcDocuments';
export const DontShowCalculation = 'DontShowCalculation';
export const DontShowCalculationMargin = 'DontShowCalculationMargin';
export const DontShowClearanceDocuments = 'DontShowClearanceDocuments';

// Department Claims
export const HasDepartmentRights = 'HasDepartmentRights';
export const IsEmailReceiver = 'IsEmailReceiver';

// Security
export const CanManageUsersInUI = 'CanManageUsersInUI';
export const CanAssignUserToTenant = 'CanAssignUserToTenant';
export const CanAssignUserToBranchOfIndustry = 'CanAssignUserToBranchOfIndustry';
export const CanAssignUserToDepartment = 'CanAssignUserToDepartment';
export const CanSetUserActiveState = 'CanSetUserActiveState';
export const CanChangeAppAdministratorState = 'CanChangeAppAdministratorState';
export const CanChangeTenantAdministratorState = 'CanChangeTenantAdministratorState';
export const IsAppAdministrator = 'AppAdministrator';
